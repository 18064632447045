<template>
  <div>
    <a-card>
      <template slot="title">
        <strong>Register Badan Usaha</strong>
        <!-- <a-range-picker class="ml-3" :format="'DD-MM-YYYY'" @change="rangedofunction"/> -->
        <a-button
          type="button"
          class="btn btn-outline-primary float-right"
          @click="connector"
        >
          Tambah Data
        </a-button>
      </template>
      <a-table
        :columns="columns"
        :dataSource="data"
        :scroll="{ x: 3700, y: 400 }"
        :pagination="false"
        size="small"
        :loading="tableLoading"
      >
        <div
          slot="filterDropdown"
          slot-scope="{
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            column,
          }"
          style="padding: 8px;"
        >
          <a-input
            v-ant-ref="(c) => (searchInput = c)"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block;"
            @change="
              (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
            "
            @pressEnter="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          />
          <a-button
            type="primary"
            icon="search"
            size="small"
            style="width: 90px; margin-right: 8px;"
            @click="
              () => handleSearch(selectedKeys, confirm, column.dataIndex)
            "
          >
            Search
          </a-button>
          <a-button
            size="small"
            style="width: 90px;"
            @click="() => handleReset(clearFilters)"
          >
            Reset
          </a-button>
        </div>
        <a-icon
          slot="filterIcon"
          slot-scope="filtered"
          type="search"
          :style="{ color: filtered ? '#108ee9' : undefined }"
        ></a-icon>
        <template
          slot="customRender"
          slot-scope="text, record, index, column"
        >
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .toString()
                .split(
                  new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                )"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                :key="i"
                class="highlight"
                >{{ fragment }}</mark
              >
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>
        <a slot="name" slot-scope="text" href="javascript:;">{{ text }}</a>
        <span slot="customTitle"><a-icon type="smile-o" /> Kode</span>
        <span slot="tags" slot-scope="tags">
          <a-tag v-for="tag in tags" color="blue" :key="tag">{{ tag }}</a-tag>
        </span>
        <span slot="action" slot-scope="text, record">
          <a-icon type="edit" style="cursor: pointer;" class="text-warning" @click="sendToEdit(record)"/>
          <a-divider type="vertical" />
          <a-icon type="delete" style="cursor: pointer;" class="text-danger" @click="showDeleteConfirm(record)"/>
          <!-- <a href="javascript:;" class="ant-dropdown-link">
            More actions <a-icon type="down" />
          </a> -->
        </span>
        <span slot="statusperkawinan" slot-scope="text, record">
          {{ statusperkawinan[record.statusperkawinan] }}
        </span>
        <span slot="jenisidentitas" slot-scope="text, record">
          {{ jenisidentitas[record.jenisidentitas] }}
        </span>
        <span slot="seumurhidup" slot-scope="text, record">
          {{ yatidak[record.seumurhidup] }}
        </span>
        <span slot="blacklist" slot-scope="text, record">
          {{ yatidak[record.blacklist] }}
        </span>
        <span slot="perorangan" slot-scope="text, record">
          {{ perorangan[record.Perorangan] }}
        </span>
        <span slot="tgl" slot-scope="text, record">
          {{ formatDate(record.tgl) }}
        </span>
        <span slot="tgllahir" slot-scope="text, record">
          {{ formatDate(record.tgllahir) }}
        </span>
        <span slot="expiredidentitas" slot-scope="text, record">
          {{ formatDate(record.expiredidentitas) }}
        </span>
      </a-table>
      <a-pagination class="mt-4" size="small" v-model="currentPage" :page-size.sync="currentPageSize" :total="totalpaginationdata" :pageSizeOptions="['10', '30', '50']" @showSizeChange="showSizeChangePaginationFunc" @change="changePaginationFunc" show-size-changer :show-total="(total, range) => `${range[0]}-${range[1]} of ${total} items`"/>
    </a-card>
  </div>
</template>

<script>
/* Import Script */
import { Modal } from 'ant-design-vue'
import * as lou from '@/services/data/lou'
import moment from 'moment'
/* End Script Import Script */

/* Kolom atau header dari table */
const columns = [
  {
    title: 'Action',
    scopedSlots: { customRender: 'action' },
    width: 70,
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    key: 'nama',
    width: 300,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.nama.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Kode',
    dataIndex: 'kode',
    rowKey: 'kode',
    width: 120,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.kode.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Sandi Bank',
    dataIndex: 'sandibank',
    rowKey: 'kode',
    width: 100,
    // fixed: 'left',
    scopedSlots: {
      filterDropdown: 'filterDropdown',
      filterIcon: 'filterIcon',
      customRender: 'customRender',
    },
    onFilter: (value, record) =>
      record.sandibank.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          this.searchInput.focus()
        }, 0)
      }
    },
  },
  {
    title: 'Jenis Badan Usaha',
    dataIndex: 'jenisbadanusaha',
    scopedSlots: { customRender: 'jenisbadanusaha' },
    width: 150,
  },
  {
    title: 'tglregister',
    dataIndex: 'tglregister',
    scopedSlots: { customRender: 'tglregister' },
    width: 100,
  },
  {
    title: 'pekerjaan',
    dataIndex: 'pekerjaan',
    scopedSlots: { customRender: 'pekerjaan' },
    width: 100,
  },
  {
    title: 'instansi',
    dataIndex: 'instansi',
    scopedSlots: { customRender: 'instansi' },
    width: 100,
  },
  {
    title: 'nip',
    dataIndex: 'nip',
    scopedSlots: { customRender: 'nip' },
    width: 150,
  },
  {
    title: 'npwp',
    dataIndex: 'npwp',
    scopedSlots: { customRender: 'npwp' },
    width: 150,
  },
  {
    title: 'alamat',
    dataIndex: 'alamat',
    scopedSlots: { customRender: 'alamat' },
    width: 800,
  },
  {
    title: 'telepon',
    dataIndex: 'telepon',
    scopedSlots: { customRender: 'telepon' },
    width: 120,
  },
  {
    title: 'hp',
    dataIndex: 'hp',
    scopedSlots: { customRender: 'hp' },
    width: 120,
  },
  {
    title: 'fax',
    dataIndex: 'fax',
    scopedSlots: { customRender: 'fax' },
    width: 120,
  },
  {
    title: 'kodepos',
    dataIndex: 'kodepos',
    scopedSlots: { customRender: 'kodepos' },
    width: 100,
  },
  {
    title: 'kodya',
    dataIndex: 'kodya',
    scopedSlots: { customRender: 'kodya' },
    width: 100,
  },
  {
    title: 'kecamatan',
    dataIndex: 'kecamatan',
    scopedSlots: { customRender: 'kecamatan' },
    width: 100,
  },
  {
    title: 'kelurahan',
    dataIndex: 'kelurahan',
    scopedSlots: { customRender: 'kelurahan' },
    width: 100,
  },
  {
    title: 'rtrw',
    dataIndex: 'rtrw',
    scopedSlots: { customRender: 'rtrw' },
    width: 100,
  },
]
/* End script Kolom atau header dari table */

/* Data dari table */
const data = []
/* End script Data dari table */

export default {
  /* Data variable vue */
  data() {
    return {
      tableLoading: false,
      currentPage: 1,
      currentPageSize: 10,
      totalpaginationdata: 0,
      searchText: '',
      tgl1: '',
      tgl2: '',
      searchInput: null,
      searchedColumn: '',
      name: 'TableRegisterBadanUsaha',
      visible: false,
      statusperkawinan: {
        K: 'Kawin',
        B: 'Belum Kawin',
        D: 'Duda',
        J: 'Janda',
      },
      jenisidentitas: {
        1: 'KTP',
        2: 'SIM',
        3: 'Pasport',
      },
      yatidak: {
        0: 'Tidak',
        1: 'Ya',
      },
      perorangan: {
        1: 'Perorangan',
        2: 'Non-Perorangan',
      },
      dataFagama: [],
      data,
      columns,
      datenow: '',
    }
  },
  /* End Data variable vue */
  mounted: function () {
    const date = moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
    localStorage.removeItem('cbsjombangeditregisterbadanusahadata')
    this.tgl1 = date
    this.tgl2 = date
    this.datenow = date.toString()
    this.getAllData()
    // this.getAgamaF()
  },
  methods: {
    moment,
    changePaginationFunc(p1, p2) {
      this.currentPage = p1
      this.getAllData()
      // console.log('p1', p1)
      // console.log('p2', p2)
      // console.log('currentPage', this.currentPage)
    },
    showSizeChangePaginationFunc(p1, p2) {
      this.getAllData()
      // console.log('currentPageSize', this.currentPageSize)
      // console.log('p1', p1)
      // console.log('p2', p2)
    },
    connector() {
      localStorage.setItem('cbsjombangeditregisterbadanusahadata', JSON.stringify({ id: '' }))
      this.$router.push('/cbsjombang/registerbadanusaha/form')
    },
    rangedofunction(date, dateString) {
      this.tgl1 = moment(dateString[0], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.tgl2 = moment(dateString[1], 'DD-MM-YYYY').format('YYYY-MM-DD')
      this.getAllData()
    },
    async getAllData() {
      this.tableLoading = true
      // console.log('this.currentPageSize', this.currentPageSize)
      // console.log('this.currentPage', this.currentPage)
      var response = await lou.customUrlGet('RegisterBadanUsaha?page=' + this.currentPage + '&perPage=' + this.currentPageSize)
      if (response) {
        this.tableLoading = false
        this.data = response.data
        this.totalpaginationdata = response.totaldata || 0
      } else {
        this.tableLoading = false
      }
    },
    async getAgamaF() {
      // var res = await lou.customUrlGet('agama')
      // this.dataFagama = res.data
      // this.$perpus.searchObj()
    },
    sendToEdit(edata) {
      // var ndata = {}
      // for (let i = 0; i < this.columns.length; i++) {
      //   const element = this.columns[i].dataIndex
      //   ndata[element.toString().toLowerCase()] = edata[element]
      // }
      localStorage.setItem('cbsjombangeditregisterbadanusahadata', JSON.stringify(edata))
      this.$router.push('/cbsjombang/registerbadanusaha/form')
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    showDeleteConfirm(deldata) {
      Modal.confirm({
        title: 'Are you sure delete this data?',
        content: "You can't prevent this!!! ",
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var response = await lou.customUrlDelete('RegisterBadanUsaha/' + deldata.kode)
          this.data = response.data
          this.getAllData()
        },
        onCancel: () => {
          console.log('Cancel')
        },
      })
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
  },
}
</script>

<style scoped>
.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
